import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../components/shared-layout";
import StudentCard from "../components/student-card";
import useStores from '../hooks/use-stores';
import { observer } from 'mobx-react';
import { CircularProgress, Divider, MenuItem, NativeSelect, Select, Tab, Tabs } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SlideDown from "react-slidedown";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SearchIcon from '@material-ui/icons/Search';
import CustomisedModal from '../components/customised-modal';
import VMCard from '../components/collapsed-card';
import IESBoxPlot from '../components/charts/iesBoxplot';
import { CHART_WIDTH } from '../constants/style';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import TabPanel from '../components/tab-panel';
import { toJS } from 'mobx';
import { Link, navigate } from 'gatsby';
import { getPermissionValidity, PermissionCategory } from '../constants/options';
import { t } from 'i18next';
import IESStackedBarChart from '../components/charts/IESStackedBarChart';

function ParentTabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const StudentDetailFilter = observer(({ callback, lastSemester = false }) => {
  const { filterStore } = useStores();
  const { t } = useTranslation();
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState(0);
  const [semDesc, setSemDesc] = useState<string>("");
  const [semesterList, setSemesterList] = useState<any>([]);
  const DEFAULT_YEAR = new Date().getFullYear().toString();

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
    setSemester(0);
    setSemesterList([]);
    filterStore.getSemesterBasedOnYear(event.target.value as string);
    if (filterStore.semesterList.length > 0) {
      var found = false;
      for (var sem of filterStore.semesterList) {
        setSemester(sem.id);
        setSemesterList([sem.id]);
        setSemDesc(filterStore.semesterList.find(s => s.id == sem.id).semesterDescription);
        found = true;
        break;
      }
      if (!found) {
        setSemester(0);
        setSemesterList([]);
      }
    }
  }

  useEffect(() => {
    filterStore.getSemesterDateList().then(() => {
      setYear(DEFAULT_YEAR);
      if (filterStore.academicYearList.length > 0) {
        filterStore.getCurrentSemester();
        // let lastSemesterId = filterStore.currentSemester - 1;
        let lastSemesterId = filterStore.currentSemester;
        for (var ay of filterStore.academicYearList) {
          if (lastSemester) {
            if (ay.academicSemesters.find(sem => sem.id == lastSemesterId)) {
              setYear(ay.yearDescription);
              filterStore.getSemesterBasedOnYear(ay.yearDescription);
              break;
            }
          } else if (ay.yearDescription == DEFAULT_YEAR) {
            setYear(ay.yearDescription);
            filterStore.getSemesterBasedOnYear(ay.yearDescription);
            break;
          }
        }
        setSemester(lastSemester ? lastSemesterId : filterStore.currentSemester);
        setSemesterList(lastSemester ? [lastSemesterId] : [filterStore.currentSemester]);
        setSemDesc(lastSemester ? filterStore.semesterList.find(s => s.id == lastSemesterId).semesterDescription
          : filterStore.semesterList.find(s => s.id == filterStore.currentSemester).semesterDescription);
      }
    });
  }, []);

  const onChangeSemster = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSemester(event.target.value as number);
    setSemesterList([event.target.value as number]);

    if (filterStore.semesterList.length > 0) {
      let semList = [filterStore.semesterList[0].id, filterStore.semesterList[1].id];
      setSemesterList(semList);
      setSemDesc(filterStore.semesterList.find(s => s.id == event.target.value as number).semesterDescription);
    }
  }

  return (
    <div className="shadow-lg rounded-xl border">
      <div className="grid grid-cols-5 gap-8 p-4">
        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-4 mt-1">
            {t('YEAR')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            style={{ height: 30 }}
            value={year}
            disabled={filterStore.academicYearList.length < 1}
            onChange={onChangeYear}
          >
            {
              filterStore.academicYearList &&
              filterStore.academicYearList.map((ay, index) => (
                <MenuItem key={`academic_year_list_${ay.id}_${index}`} value={ay.yearDescription}>
                  {ay.yearDescription}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-4 mt-1">
            {t('SEMESTER')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            style={{ height: 30 }}
            value={semester}
            onChange={onChangeSemster}
            disabled={year == ""}
          >
            {
              filterStore.semesterList.length > 0 &&
              filterStore.semesterList.map((sem, index) => (
                <MenuItem key={`semester_list_${sem.id}_${index}`} value={sem.id}>
                  {sem.semesterDescription.substring(8, 10)}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="w-full text-right">
          <Button
            className="bg-buttonPrimary text-white text-sm"
            disabled={year == ""}
            onClick={() => {
              callback({
                year,
                semester,
                semesterList,
                semDesc,
              })
            }}
          >
            {t('SEARCH')}
          </Button>
        </div>
      </div>
    </div>
  );
});

const Dashboard = observer(() => {
  const { t } = useTranslation();
  const fieldStyle = "text-gray-500 ml-2";
  const [userInfo, setUserInfo] = useState<any>(
    typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO)
  );
  const [parentTab, setParentTab] = useState<number>(0);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.USER_INFO) setUserInfo(JSON.parse(localStorage.USER_INFO));
      if (localStorage.SD_TAB_HISTORY) setParentTab(+JSON.parse(localStorage.SD_TAB_HISTORY).tab);
    }
  }, []);

  const onChangeParentTab = (event, newValue) => {
    setParentTab(newValue);
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showFilter={false}
          showYearSemTerm={false}
          showClassCode={false}
          showDomain={false}
          showStaffList={false}
          showNewsArea={true}
          showSeachStudent={true}
        >
          <title>{t('DASHBOARD')}</title>
          <div className="col-span-4 flex flex-col m-2">
            <div className="h-36 flex flex-row rounded-xl border shadow-lg mb-4">
              {
                !userInfo ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <>
                    <div className="w-40 p-2 mr-4">
                      <img
                        className="cursor-pointer h-full w-full object-cover rounded-full border"
                        src={userInfo.photo}
                        alt="studentImg"
                      />
                    </div>

                    <div className="w-full grid grid-cols-3">
                      <div className={`${fieldStyle} mt-6`}>
                        {t('TEACHER')} ID: <span className={`ml-2`}>{userInfo.uid}</span>
                      </div>
                      <div className={`${fieldStyle} mt-6`}>
                        {t('FIRST_NAME')}: <span className={`ml-2`}>{userInfo.firstName}</span>
                      </div>
                      <div className={`${fieldStyle} mt-6`}>
                        {t('SURNAME')}: <span className={`ml-2`}>{userInfo.lastName}</span>
                      </div>
                      <div className={`${fieldStyle} col-span-3`}>
                        {t('EMAIL')}: <span className={`ml-2`}>{userInfo.email}</span>
                      </div>
                    </div>
                  </>
              }
            </div>

            <Tabs
              value={parentTab}
              onChange={onChangeParentTab}
              TabIndicatorProps={{ style: { display: "none" } }}
              className="shadow-lg border rounded-lg mb-4"
            >
              {getPermissionValidity(PermissionCategory.TEACHER, "Overview", "Overview") &&
                <Tab
                  label={t('OVERVIEW')}
                  icon={<DashboardIcon />}
                  style={{ minWidth: 120 }}
                  {...ParentTabProps(0, 0 === parentTab)}
                />}

              {getPermissionValidity(PermissionCategory.TEACHER, "Teaching", "Teaching") &&
                <Tab
                  label={t('MY_TEACHING')}
                  icon={<VisibilityIcon />}
                  {...ParentTabProps(1, 1 === parentTab)}
                  style={{ minWidth: 120 }}
                />}

              {getPermissionValidity(PermissionCategory.TEACHER, "Student", "Student") &&
                <Tab
                  label={t('MY_STUDENT')}
                  icon={<PersonOutlineIcon />}
                  {...ParentTabProps(2, 2 === parentTab)}
                  style={{ minWidth: 120 }}
                />}

            </Tabs>

            <TabPanel value={parentTab} index={0}>
              <OverviewTab userInfo={userInfo} />
            </TabPanel>


            <TabPanel value={parentTab} index={1}>
              <MyTeachingTab userInfo={userInfo} />
            </TabPanel>

            <TabPanel value={parentTab} index={2}>
              <MyStudentTab userInfo={userInfo} />
            </TabPanel>
          </div>
        </Layout>
      }
    </>
  )
})

const OverviewTab = observer(({ userInfo }) => {
  const { teacherStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [msgDropdown, setMsgDropdown] = useState<any>([]);
  const [staffMsgDropdown, setStaffMsgDropdown] = useState<any>([]);
  const [markingStatisticDropdown, setMarkingStatisticDropdown] = useState<boolean>(false);
  const [taskDueDateDropdown, setTaskDueDateDropdown] = useState<boolean>(true);
  const [dailyMsgSectDropdown, setDailyMsgSectDropdown] = useState<boolean>(true);
  const [staffMsgSectDropdown, setStaffMsgSectDropdown] = useState<boolean>(true);
  const [timetableDropdown, setTimetableDropdown] = useState<boolean>(true);
  const [showDueTaskDetail, setShowDueTaskDetail] = useState<boolean>(false);
  const [taskDetail, setTaskDetail] = useState<any>([]);
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    // if (teacherStore.taskDueDateList.length == 0) {
    // teacherStore.getTaskDueDateList(userInfo.uid, "2021-10-11");
    // teacherStore.getTaskDueDateList(userInfo.uid, today);
    // }

    // if (teacherStore.teacherTimetable.length < 1) {
    // teacherStore.getTeacherTimetable(userInfo.uid, "2021-11-04");
    // teacherStore.getTeacherTimetable(userInfo.uid, today);
    // }

    // if (teacherStore.dailyMessageList.length < 1) {
    // teacherStore.getTeacherDailyMessage(userInfo.uid, "2021-11-04")
    // teacherStore.getTeacherDailyMessage(userInfo.uid, today)
    //   .then(() => {
    //     let temp = [];
    //     for (var msg of teacherStore.dailyMessageList) {
    //       let msgToMinimised = {
    //         catId: msg.categoryCode,
    //         isMinimised: msg.isMinimised
    //       }
    //       temp.push(msgToMinimised);
    //     }
    //     setMsgDropdown(temp);
    //   });
    // }

    // if (teacherStore.teacherStaffMsgList.length < 1) {
    // teacherStore.getTeacherStaffMessage(userInfo.uid, "2017-12-11")
    // teacherStore.getTeacherStaffMessage(userInfo.uid, today)
    //   .then(() => {
    //     let temp: any[] = [];
    //     for (var msg of teacherStore.teacherStaffMsgList) {
    //       let msgToMinimised = {
    //         catId: msg.categoryCode,
    //         isMinimised: msg.isMinimised
    //       }
    //       temp.push(msgToMinimised);
    //     }
    //     setStaffMsgDropdown(temp);
    //   });
    // }

    if (filterStore.academicYearList.length > 0) {
      if (filterStore.currentSemester == 0) filterStore.getCurrentSemester();
      // if (teacherStore.teacherMarkingStatistics.length < 1) {
      teacherStore.getTeacherMarkingStatistics(userInfo.uid, filterStore.currentSemester);
      // }
    } else {
      filterStore.getSemesterDateList().then(() => {
        filterStore.getCurrentSemester();
        // if (teacherStore.teacherMarkingStatistics.length < 1) {
        teacherStore.getTeacherMarkingStatistics(userInfo.uid, filterStore.currentSemester)
          .then((res) => {
            if (!res || res.length == 0) teacherStore.getTeacherMarkingStatistics(userInfo.uid, filterStore.currentSemester - 1)
          });
        // } 
      })
    }
  }, []);

  return (
    <>
      <div className='p-4 border rounded-xl mb-4 shadow-lg'>
        <a
          className={`cursor-pointer flex justify-between`}
          onClick={(e) => {
            e.preventDefault();
            setMarkingStatisticDropdown(!markingStatisticDropdown);
          }}>
          <div className="text-barPrimary text-lg font-bold uppercase">{t('MARKING_STATISTICS')}: {today.split('-')[2] + "/" + today.split('-')[1] + "/" + today.split('-')[0]}</div>
          <span className="text-gray-400">{markingStatisticDropdown ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}</span>
        </a>
        <SlideDown
          className={'pure-menu pure-menu-scrollable'}
          closed={markingStatisticDropdown}
        >
          {
            teacherStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              <div className="overflow-auto" style={{ maxHeight: 500 }}>
                <table width="100%" className="mt-4">
                  <thead className="bg-barPrimary text-white text-sm">
                    <td className="p-2">{t('TASK_NAME')}</td>
                    <td className="px-2">{t('CLASSCODE')}</td>
                    <td className="px-2">Task Type</td>
                    <td className="px-2">Marked Percentage (Marked Submission / No. Submissions)</td>
                    <td className="px-2">No. Submitted</td>
                    <td className="px-2">No. Not Submitted yet</td>
                    <td className="px-2">Total Students</td>
                    <td className="text-right px-2">{t('AVG_DAY_BTW_MARKING_DATE_SUBMISSION_DATE')}</td>
                  </thead>
                  <tbody className="text-sm">
                    {teacherStore.teacherMarkingStatistics.map((task: any, index: number) => (
                      <tr
                        key={`task_marking_statistics_${index}`}
                        className={`mt-4 ${index % 2 != 0 && 'bg-gray-100'}`}
                      >
                        <td className="px-2">{task.taskName}</td>
                        <td className="px-2">{task.classCode}</td>
                        <td className="px-2">{task.type}</td>
                        <td className="px-2">{task.markedPercentage}{+task.markedPercentage >= 0 && '%' + ` (${+task.numberOfSubmission - +task.notMarked} / ${task.numberOfSubmission})`}</td>
                        <td className="px-2">{+task.numberOfSubmission}</td>
                        <td className="px-2">{+task.notSubmitted}</td>
                        <td className="px-2">{+task.notSubmitted + +task.numberOfSubmission}</td>
                        <td className="text-right p-2">
                          {task.averageDayBetweenMarkingDateAndSubmissionDate == 0 ? 0 + " " + t('DAYS')
                            : !task.averageDayBetweenMarkingDateAndSubmissionDate.includes("No") ?
                              task.averageDayBetweenMarkingDateAndSubmissionDate + " " + t('DAYS')
                              : task.averageDayBetweenMarkingDateAndSubmissionDate == "No Result" ?
                                "-" : task.averageDayBetweenMarkingDateAndSubmissionDate}
                        </td>
                      </tr>
                    ))}
                    {teacherStore.teacherMarkingStatistics.length == 0 &&
                      <tr><td className='text-center p-2' colSpan={8}>{t('NO_RESULT_FOUND')}</td></tr>}
                  </tbody>
                </table>
              </div>
          }
        </SlideDown>
      </div>

      {/* <div className='p-4 border rounded-xl mb-4 shadow-lg'>
        <a
          className={`cursor-pointer flex justify-between`}
          onClick={(e) => {
            e.preventDefault();
            setTaskDueDateDropdown(!taskDueDateDropdown);
          }}>
          <div className="text-barPrimary text-lg font-bold uppercase">{t('TASK_DUE_DATE_LIST')}: {today.split('-')[2] + "/" + today.split('-')[1] + "/" + today.split('-')[0]}</div>
          <span className="text-gray-400">{taskDueDateDropdown ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}</span>
        </a>
        <SlideDown
          className={'pure-menu pure-menu-scrollable'}
          closed={taskDueDateDropdown}
        > */}
      {/* {teacherStore.loadingTaskDueList ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            : <div className="max-h-96 overflow-auto">
              <table width="100%" className="mt-4">
                <thead className="bg-barPrimary text-white">
                  <td className="p-2">{t('CLASSCODE')}</td>
                  <td>{t('TASK')}</td>
                  {/* <td className="text-right px-2">{t('STUDENT_AMOUNT')}</td>
                  <td className="text-right px-2">Submission/Total</td>
                  <td className="text-right px-2">{t('DUE_DATE')}</td>
                </thead>
                <tbody>
                  {teacherStore.taskDueDateList.map((task: any, index: number) => (
                    <tr
                      key={`task_due_date_${index}`}
                      className={`mt-4 ${index % 2 != 0 && 'bg-gray-100'}`}
                    >
                      <td className="px-2">{task.classCode}</td>
                      <td className="w-30">{task.taskTitle}</td>
                      <td className="text-right p-2">
                        <button
                          className={`${task.submittedStudentNumber != task.totalStudentNumber && 'text-red-500'} hover:underline`}
                          onClick={() => {
                            setTaskDetail(task.submissionDetail);
                            setShowDueTaskDetail(true);
                          }}
                        >
                          {task.submittedStudentNumber}/{task.totalStudentNumber} <SearchIcon color={'action'} />
                        </button>
                      </td>
                      <td className="text-right p-2">{task.dueDate.split('T')[0]}</td>
                    </tr>
                  ))}
                  {!teacherStore.taskDueDateList || teacherStore.taskDueDateList.length == 0 && <tr><td className='text-center p-2' colSpan={4}>-</td></tr>}
                </tbody>
              </table>
            </div>
          } */}
      {/* </SlideDown>
      </div> */}

      <CustomisedModal showModal={showDueTaskDetail}>
        <button
          className="fixed top-0 right-0 p-2 text-white bg-barPrimary"
          onClick={() => setShowDueTaskDetail(false)}>
          <ClearIcon fontSize={'large'} />
        </button>
        <div
          className="bg-white absolute top-1/2 left-1/2 overflow-auto border-t-8 border-barPrimary"
          style={{ transform: 'translate(-50%, -50%)', maxHeight: 600, width: '60%' }}
        >
          {
            taskDetail.length > 0 &&
            <table width="100%">
              <thead className="bg-barPrimary text-white">
                <td className="p-2 text-center">{t('NAME')}</td>
                <td className="text-center">{t('HOMEROOM_CODE')}</td>
                <td className="text-center px-2">{t('SUBMISSION_DATE')}</td>
                <td className="text-center px-2">{t('IS_MARKED')}</td>
              </thead>
              <tbody>
                {
                  taskDetail.map((task: any, index: number) => (
                    <tr
                      key={`task_detail_${index}`}
                      className={`mt-4 ${index % 2 != 0 && 'bg-gray-100'}`}
                    >
                      <td className="p-2 text-center">{task.name}</td>
                      <td className="text-center">{task.homeroomCode}</td>
                      <td className="p-2 text-center">
                        {task.submissionDate && task.submissionDate.split('T')[0] + ' ' + task.submissionDate.split('T')[1].substring(0, 8)}
                      </td>
                      <td className="p-2 text-green-400 text-center">{task.isMarked ? <CheckIcon color={'inherit'} fontSize={'large'} /> : <ClearIcon color={'error'} fontSize={'large'} />}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          }
        </div>
      </CustomisedModal>

      {/* <div className='p-4 border rounded-xl mb-4 shadow-lg'>
        <a
          className={`cursor-pointer flex justify-between`}
          onClick={(e) => {
            e.preventDefault();
            setDailyMsgSectDropdown(!dailyMsgSectDropdown);
          }}>
          <div className="text-barPrimary text-lg font-bold uppercase">{t('DAILY_MESSAGE')}: {today.split('-')[2] + "/" + today.split('-')[1] + "/" + today.split('-')[0]}</div>
          <span className="text-gray-400">{dailyMsgSectDropdown ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}</span>
        </a>
        <SlideDown
          className={'pure-menu pure-menu-scrollable'}
          closed={dailyMsgSectDropdown}
        >
          {
            teacherStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              teacherStore.dailyMessageList.length > 0 &&
                teacherStore.dailyMessageList.filter((msg: any) => msg.messages.length > 0).length > 0 ?
                teacherStore.dailyMessageList.filter((msg: any) => msg.messages.length > 0)
                  .map((msg: any, index: number) => (
                    <div
                      key={`daily_msg_${index}`}
                      className={`p-2 mt-4`}
                    >
                      <a
                        className={`cursor-pointer flex bg-gray-100 p-2 text-barPrimary font-semibold border justify-between`}
                        onClick={(e) => {
                          e.preventDefault();
                          if (typeof window !== "undefined" && localStorage.USER_INFO && msgDropdown.length > 0) {
                            teacherStore.updateDailyMessageCategorySetting(
                              {
                                uid: JSON.parse(localStorage.USER_INFO).uid,
                                catId: msg.categoryCode,
                                isMinimised: !msgDropdown.find((data) => data.catId == msg.categoryCode).isMinimised
                              }
                            )
                            let temp = [];

                            for (var drop of msgDropdown) {
                              let linkToMinimised = {
                                catId: drop.catId,
                                isMinimised: drop.catId == msg.categoryCode ? !drop.isMinimised : drop.isMinimised
                              }
                              temp.push(linkToMinimised);
                            }
                            setMsgDropdown(temp);
                          }
                        }}
                      >
                        {msg.categoryDescription}
                        {msgDropdown.length > 0 && msgDropdown.find((data) => data.catId == msg.categoryCode).isMinimised ?
                          <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </a>
                      <SlideDown
                        className={'pure-menu pure-menu-scrollable'}
                        closed={
                          msgDropdown.length > 0 ? msgDropdown.find((data) => data.catId == msg.categoryCode).isMinimised
                            : true
                        }
                      >
                        {msg.messages.map((data: any, index: number) => (
                          <div key={`individual_daily_msg_${index}`} className="px-2 border border-t-0 p-2">
                            <div className='text-barPrimary text-semidbold'>
                              {data.messageTitle}
                              <span className='text-lg ml-2'>
                                {data.isNew && <AnnouncementIcon className='text-red-700' fontSize={'inherit'} />}
                              </span>
                            </div>

                            <div className='text-sm text-gray-600 mt-2'>{data.messageContent}</div>
                          </div>
                        ))}
                      </SlideDown>
                    </div>
                  ))
                : <div className='text-center p-2'>{t('NO_MSG_FOUND')}</div>
          }
        </SlideDown>
      </div> */}

      {/* <div className='p-4 border rounded-xl mb-4 shadow-lg'>
        <a
          className={`cursor-pointer flex justify-between`}
          onClick={(e) => {
            e.preventDefault();
            setStaffMsgSectDropdown(!staffMsgSectDropdown);
          }}>
          <div className="text-barPrimary text-lg font-bold uppercase">{t('TEACHER_STAFF_MSG')}: {today.split('-')[2] + "/" + today.split('-')[1] + "/" + today.split('-')[0]}</div>
          <span className="text-gray-400">{staffMsgSectDropdown ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}</span>
        </a>
        <SlideDown
          className={'pure-menu pure-menu-scrollable'}
          closed={staffMsgSectDropdown}
        >
          {
            teacherStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              teacherStore.teacherStaffMsgList.length > 0 &&
                teacherStore.teacherStaffMsgList.filter((msg: any) => msg.messages.length > 0).length > 0 ?
                teacherStore.teacherStaffMsgList.filter((msg: any) => msg.messages.length > 0)
                  .map((msg: any, index: number) => (
                    <div
                      key={`staff_teacher_msg_${index}`}
                      className={`p-2 mt-4`}
                    >
                      <a
                        className={`cursor-pointer flex bg-gray-100 p-2 text-barPrimary font-semibold border justify-between`}
                        onClick={(e) => {
                          e.preventDefault();
                          if (typeof window !== "undefined" && localStorage.USER_INFO && staffMsgDropdown.length > 0) {
                            teacherStore.updateStaffMessageCategorySetting(
                              {
                                uid: JSON.parse(localStorage.USER_INFO).uid,
                                catId: msg.categoryCode,
                                isMinimised: !msgDropdown.find((data) => data.catId == msg.categoryCode).isMinimised
                              }
                            )
                            let temp = [];
                            for (var drop of staffMsgDropdown) {
                              let linkToMinimised = {
                                catId: drop.catId,
                                isMinimised: drop.catId == msg.categoryCode ? !drop.isMinimised : drop.isMinimised
                              }
                              temp.push(linkToMinimised);
                            }
                            setStaffMsgDropdown(temp);
                          }
                        }}
                      >
                        {msg.categoryDescription}
                        {staffMsgDropdown.length > 0 && staffMsgDropdown.find((data) => data.catId == msg.categoryCode).isMinimised ?
                          <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </a>
                      <SlideDown
                        className={'pure-menu pure-menu-scrollable'}
                        closed={
                          staffMsgDropdown.length > 0 ? staffMsgDropdown.find((data) => data.catId == msg.categoryCode).isMinimised
                            : true
                        }
                      >
                        {msg.messages.map((data: any, index: number) => (
                          <div key={`individual_daily_msg_${index}`} className="px-2 border border-t-0 p-2">
                            <div className='text-barPrimary text-semidbold'>
                              {data.messageTitle}
                              <span className='text-lg ml-2'>
                                {data.isNew && <AnnouncementIcon className='text-red-700' fontSize={'inherit'} />}
                              </span>
                            </div>

                            <div className='text-sm text-gray-600 mt-2'>{data.messageContent}</div>
                          </div>
                        ))}
                      </SlideDown>
                    </div>
                  ))
                : <div className='text-center p-2'>{t('NO_MSG_FOUND')}</div>
          }
        </SlideDown>
      </div> */}

      {/* <div className='p-4 border rounded-xl mb-4 shadow-lg'>
        <a
          className={`cursor-pointer flex justify-between`}
          onClick={(e) => {
            e.preventDefault();
            setTimetableDropdown(!timetableDropdown);
          }}>
          <div className="text-barPrimary text-lg font-bold uppercase">{t('TIMETABLE')}: {today.split('-')[2] + "/" + today.split('-')[1] + "/" + today.split('-')[0]}</div>
          <span className="text-gray-400">{timetableDropdown ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}</span>
        </a>

        <SlideDown
          className={'pure-menu pure-menu-scrollable'}
          closed={timetableDropdown}>
          {
            teacherStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              teacherStore.teacherTimetable.length > 0 ?
                <table width="100%" className="mt-4">
                  <tbody>
                    {teacherStore.teacherTimetable.map((time: any, index: number) => (
                      <tr
                        key={`timetable_teacher_${index}`}
                        className={`p-2 ${!time.periodDescription.includes('Period') &&
                          !time.periodDescription.includes('Mentor') && 'bg-gray-200'}`}
                      >
                        <td className="text-barPrimary font-semibold p-2">
                          {time.periodDescription}  {time.isCancelled && '(' + t('CANCELLED') + ')'}
                        </td>
                        <td className="font-base">{time.classCode && time.classCode} {time.roomCode && "in Room " + time.roomCode}</td>
                        <td className="text-right px-2">
                          {time.startTime.split('T')[1].substring(0, time.startTime.split('T')[1].length - 3)} {+time.startTime.substring(0, 3) < 12 ? 'AM' : 'PM'}
                          <span className='mx-1'> - </span>
                          {time.endTime.split('T')[1].substring(0, time.endTime.split('T')[1].length - 3)} {+time.endTime.substring(0, 3) < 12 ? 'AM' : 'PM'}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
                : <div className='text-center p-2'>{t('NO_RESULT_FOUND')}</div>
          }
        </SlideDown>
      </div> */}
    </>
  )
});

const MyTeachingTab = observer(({ userInfo }) => {
  const { teacherStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [sPerformanceData, setSPerformanceData] = useState<any>([]);
  const [currentSubject, setCurrentSubject] = useState<string>("");
  const [classPerformanceData, setClassPerformanceData] = useState<any>([]);
  const [currentClassCode, setCurrentClassCode] = useState<string>("");
  const [activeTaskDetail, setActiveTaskDetail] = useState<any>({ yAxis: [], series: [] });
  const [activeTaskTitle, setActiveTaskTitle] = useState<string>("");
  const [semester, setSemester] = useState<number>(0);
  const [year, setYear] = useState<string>("");
  enum SortMode {
    BY_SCORE = "score",
    BY_NAME = "name"
  }
  const [sortMode, setSortMode] = useState<SortMode>(SortMode.BY_SCORE);
  const [filterTitle, setFilterTitle] = useState<string>("");
  const [minNum, setMinNum] = useState<number>(0);
  const [stackeChartLegend, setStackedChartLegend] = useState<any>([]);
  const [nonNumericalPerformance, setNonNumericalPerformance] = useState<any>({ yAxis: [], series: [] });

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.SD_TAB_HISTORY) {
        onApplyFilter({ year: JSON.parse(localStorage.SD_TAB_HISTORY).year, semester: JSON.parse(localStorage.SD_TAB_HISTORY).semester });
        delete localStorage.SD_TAB_HISTORY;
      }
      else {
        if (filterStore.currentSemester > 0)
          onApplyFilter({ semester: filterStore.currentSemester, firstLoad: true });
      }
    }
  }, [filterStore.currentSemester]);

  const onChangeCurrentClassCode = (classCode: string, mode: SortMode) => {
    let tempSPerform = [];
    let tempSPerformCat = [];
    let ownClassList: any = [];

    let longestMarkingLabel: number = 0;
    let longestMarkingClassCode: string = "";

    if (teacherStore.teacherSubjectPerformList.length > 0) {
      if (classCode === "") {
        ownClassList = teacherStore.teacherSubjectPerformList[0].subjectPerformance.find(s => s.isOwnClass == true);
        setCurrentClassCode(teacherStore.teacherSubjectPerformList[0].subjectPerformance.find(s => s.isOwnClass == true).classCode);
      } else {
        for (var subject of teacherStore.teacherSubjectPerformList) {
          var found = false;
          for (var student of subject.subjectPerformance) {
            if (student.classCode === classCode) {
              ownClassList = student;
              setCurrentClassCode(classCode);
              found = true;
              break;
            }
          }
          if (found) break;
        }
      }

      const compare = (a, b) => {
        if (mode === SortMode.BY_NAME ? (a.name < b.name) : (+a.score > +b.score))
          return -1;
        if (mode === SortMode.BY_NAME ? (a.name > b.name) : (+a.score < +b.score))
          return 1;
        return 0;
      }

      let sortedStudentPerformList = ownClassList.studentPerformance.sort(compare)
      console.log("GetTeacherSubjectPerformance", "sort", toJS(ownClassList), toJS(sortedStudentPerformList));
      let tempMinNum = 1000;
      for (var student of sortedStudentPerformList) {
        tempSPerform.push(sortedStudentPerformList.map(s => +s.score));
        let min = Math.min.apply(Math, sortedStudentPerformList.map(s => +s.score));
        if (min < tempMinNum) tempMinNum = min;
      }
      setMinNum(tempMinNum - 10);
      sortedStudentPerformList.map(s => tempSPerformCat.push(s.name));
      setSPerformanceData({
        boxData: tempSPerform,
        category: tempSPerformCat,
        markComparisionData: sortedStudentPerformList.map(s => +s.score),
      });
    } else {
      setSPerformanceData({
        boxData: tempSPerform,
        category: tempSPerformCat,
        markComparisionData: [],
      })
    }
  }

  const onSortPerformanceList = (mode: SortMode) => {
    onChangeCurrentClassCode(currentClassCode, mode);
    setSortMode(mode);
  }

  const onChangeCurrentSubject = (subject: string) => {
    let tempClassData = [];
    let tempOwnClassData = [];
    let current = "";
    if (teacherStore.teacherSubjectPerformList.length > 0) {
      if (subject === "") {
        setCurrentSubject(teacherStore.teacherSubjectPerformList[0].subject);
        current = teacherStore.teacherSubjectPerformList[0].subject;
      } else {
        setCurrentSubject(subject);
        current = subject;
      }
      const compare = (a, b) => {
        if ((a.classCode < b.classCode)) return -1;
        if (a.classCode > b.classCode) return 1;
        return 0;
      }
      let filteredSubjectClassList = teacherStore.teacherSubjectPerformList
        .find(s => s.subject === current).subjectPerformance.sort(compare);


      let countNonNumericalLength: number = 0;
      let longestClassCodeLegend: string[] = [];
      for (var tempSubject of filteredSubjectClassList) {
        tempClassData.push(tempSubject.studentPerformance.map(s => +s.score));
        if (countNonNumericalLength < Object.keys(tempSubject.studentNonNumericalPerformance.performanceSummary).length) {
          countNonNumericalLength = Object.keys(tempSubject.studentNonNumericalPerformance.performanceSummary).length;
          longestClassCodeLegend = [];
          for (const key in tempSubject.studentNonNumericalPerformance.performanceSummary) {
            longestClassCodeLegend.push(key);
          }
        }
      }
      setStackedChartLegend(longestClassCodeLegend);
      let tempYAxis = filteredSubjectClassList.map(s => s.isOwnClass == true ? "My Class: " + s.classCode : s.classCode);

      let nonNumericalSeries: any[] = [];
      for (var label of longestClassCodeLegend) {
        let series: any = []
        filteredSubjectClassList.map((s: any) => {
          series.push({
            value: s.studentNonNumericalPerformance.performanceSummary[label] ? s.studentNonNumericalPerformance.performanceSummary[label] : 0,
            label: { show: s.studentNonNumericalPerformance.performanceSummary[label] ? +s.studentNonNumericalPerformance.performanceSummary[label] > 0 : false }
          });
        })
        nonNumericalSeries.push({
          name: label,
          type: "bar",
          stack: "total",
          emphasis: { focus: 'series' },
          data: series,
          barWidth: 50,
        });
      }

      setNonNumericalPerformance({
        yAxis: tempYAxis,
        series: nonNumericalSeries,
      })

      console.log("666", {
        yAxis: toJS(tempYAxis),
        series: toJS(nonNumericalSeries)
      });




      setClassPerformanceData({
        boxData: tempClassData,
        // secondBoxData: tempOwnClassData,
        category: filteredSubjectClassList.map(s => s.isOwnClass ? "My Class: " + s.classCode : s.classCode)
      });
    } else {
      setClassPerformanceData({
        boxData: tempClassData,
        // secondBoxData: tempOwnClassData,
        category: []
      });
      setNonNumericalPerformance({
        yAxis: [],
        series: []
      })
    }

  }

  const onApplyFilter = (dataInfo: any) => {
    console.log(dataInfo);

    setSemester(dataInfo.semester);
    teacherStore.getTeacherSubjectPerformance(userInfo.uid, dataInfo.semester)
      .then((res) => {
        // if first load the result is empty
        if (dataInfo.firstLoad && filterStore.currentSemester == dataInfo.semester && res.length == 0) {
          teacherStore.getTeacherSubjectPerformance(userInfo.uid, +dataInfo.semester - 1)
            .then(() => {
              // Student Performance
              onChangeCurrentClassCode("", sortMode);
              // Class Performance
              onChangeCurrentSubject("");

              for (var year of filterStore.academicYearList) {
                if (year.academicSemesters.find(s => s.id == dataInfo.semester - 1)) {
                  dataInfo.semDesc = year.academicSemesters.find(s => s.id == dataInfo.semester - 1).semesterDescription;
                  dataInfo.year = year.yearDescription;
                  break;
                }
              }
              setYear(dataInfo.year);
              setFilterTitle(`Year ${dataInfo.year} - ${dataInfo.semDesc}`);
            })
        } else {
          // Student Performance
          onChangeCurrentClassCode("", sortMode);
          // Class Performance
          onChangeCurrentSubject("");
        }
      });

    if (!dataInfo.year) {
      dataInfo.year = new Date().getFullYear().toString();
      dataInfo.semDesc = filterStore.academicYearList.find(ay => ay.yearDescription === dataInfo.year).academicSemesters
        .find(s => s.id == dataInfo.semester).semesterDescription;
    } else if (dataInfo.year && dataInfo.semester && !dataInfo.semDesc) {
      dataInfo.semDesc = filterStore.academicYearList.find(ay => ay.yearDescription === dataInfo.year).academicSemesters
        .find(s => s.id == dataInfo.semester).semesterDescription;
    }
    setYear(dataInfo.year);
    setFilterTitle(`Year ${dataInfo.year} - ${dataInfo.semDesc}`)
  }

  const onDirectStudentDetail = {
    click: function (params) {
      let ownClassList: any[] = [];
      teacherStore.teacherSubjectPerformList.map((tsp: any) => {
        if (tsp.subjectPerformance.filter(s => s.isOwnClass == true)) {
          ownClassList = [...ownClassList, ...tsp.subjectPerformance.filter(s => s.isOwnClass == true)];
        }
      });
      let targetUid = ownClassList.find(c => c.classCode === currentClassCode).studentPerformance
        .find(s => s.name === params.name).uid;
      localStorage.SD_TAB_HISTORY = JSON.stringify({ tab: 1, semester, year });
      navigate("/student-detail/", { state: { student: targetUid, semester: [semester] } });
    }
  }

  const onDirectStudentOverview = {
    click: function (params) {
      // console.log(params);
      if (filterStore.domainList.length > 0) {
        // console.log("???", toJS(filterStore.domainList));
        for (var domain of filterStore.domainList) {
          if (domain.systemDefinedSubjects.find(subject => params.name.includes(subject.subjectCode))) {
            let filteredSubjects: any = domain.systemDefinedSubjects.find(subject => params.name.includes(subject.subjectCode));
            if (filteredSubjects.systemDefinedSubjectClasses.find(s => s.fileSeq == semester && params.name.includes(s.classCode))) {
              localStorage.SD_TAB_HISTORY = JSON.stringify({ tab: 1, semester, year });
              navigate("/student-overview/", {
                state: {
                  semester: [semester],
                  domainId: domain.id,
                  subjectClassIdList: [filteredSubjects.systemDefinedSubjectClasses.find(s => s.fileSeq == semester && params.name.includes(s.classCode)).id],
                  classCode: params.name
                }
              });
              break;
            }
          }
        }
      } else {
        filterStore.getDomainList().then(() => {
          for (var domain of filterStore.domainList) {
            if (domain.systemDefinedSubjects.find(subject => params.name.includes(subject.subjectCode))) {
              let filteredSubjects: any = domain.systemDefinedSubjects.find(subject => params.name.includes(subject.subjectCode));
              if (filteredSubjects.systemDefinedSubjectClasses.find(s => s.fileSeq == semester && params.name.includes(s.classCode))) {
                localStorage.SD_TAB_HISTORY = JSON.stringify({ tab: 1, semester, year });
                navigate("/student-overview/", {
                  state: {
                    semester: [semester],
                    domainId: domain.id,
                    subjectClassIdList: [filteredSubjects.systemDefinedSubjectClasses.find(s => s.fileSeq == semester && params.name.includes(s.classCode)).id]
                  }
                });
                break;
              }
            }
          }
        })
      }
    }
  }

  const onClickBarChart = {
    click: function (params) {
      if (teacherStore.teacherSubjectPerformList.length > 0) {
        teacherStore.teacherSubjectPerformList.map((tsp: any) => {
          let title = params.name.includes("My Class") ? params.name.split(' ')[2] : params.name
          if (tsp.subjectPerformance.find(sp => sp.classCode === title)) {
            let target = tsp.subjectPerformance.find(sp => sp.classCode === title);

            let nonNumericalSeries: any[] = [];
            for (var label of stackeChartLegend) {
              let series: any = []
              target.studentNonNumericalPerformance.taskDetail.map((t: any) => {
                series.push({
                  value: t.performance[label] ? t.performance[label] : 0,
                  label: { show: t.performance[label] ? +t.performance[label] > 0 : false }
                });
              })
              nonNumericalSeries.push({
                name: label,
                type: "bar",
                stack: "total",
                emphasis: { focus: 'series' },
                data: series,
                barWidth: 50,
              });
            }

            setActiveTaskTitle(params.name);
            setActiveTaskDetail({
              yAxis: target.studentNonNumericalPerformance.taskDetail.map(t => t.taskTitle.length > 35 ? t.taskTitle.substring(0, 35) + "..." : t.taskTitle),
              series: nonNumericalSeries,
            });
          }
        })
      }
    },
  };

  return (
    <>
      <StudentDetailFilter callback={onApplyFilter} lastSemester />

      <VMCard
        className="mb-4"
        titleClassName="m-4 mr-0"
        titleComponent={
          <div className="text-barPrimary text-lg font-bold uppercase">{t('CLASS_PERFORMANCE')}: {filterTitle}</div>
        }>
        {
          teacherStore.loading || !classPerformanceData.boxData ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <>
              <div className='text-right'>
                {
                  teacherStore.teacherSubjectPerformList.map((tsp: any, index: number) => (
                    <Button
                      className={`rounded-lg text-white text-sm mr-4 ${tsp.subject === currentSubject ? "bg-barPrimary" : "bg-gray-400"}`}
                      key={`tsp_subject_button_${index}`}
                      component="span"
                      onClick={() => onChangeCurrentSubject(tsp.subject)}
                    >
                      {tsp.subject === currentSubject ? t('CURRENT') + ": " + tsp.subject : tsp.subject}
                    </Button>
                  ))
                }
              </div>

              {
                classPerformanceData.boxData.length > 0 ?
                  <>
                    <IESBoxPlot
                      dataAndSetting={{
                        data: classPerformanceData,
                        height: '350px',
                        width: CHART_WIDTH,
                        xAxisLabelRotate: 45,
                      }}
                      callback={onDirectStudentOverview}
                    />
                    <Divider className='mb-4' />
                    <div className="text-sm text-barPrimary text-center mb-2">Tip: Click bar to view the task detail for given classcode</div>
                    <IESStackedBarChart
                      setting={{
                        xAxis: nonNumericalPerformance.yAxis,
                        series: nonNumericalPerformance.series,
                        height: 400,
                        width: '100%'
                      }}
                      onEvents={onClickBarChart}
                    />
                    <Divider className='my-4' />
                    {activeTaskDetail && activeTaskTitle !== "" &&
                      <>
                        <div className="text-barPrimary text-lg font-bold uppercase mx-4 mb-2">Task Detail - {activeTaskTitle}</div>
                        <IESStackedBarChart
                          setting={{
                            xAxis: activeTaskDetail.yAxis,
                            series: activeTaskDetail.series,
                            height: 400,
                            width: '100%',
                            rotate: 360,
                          }}
                        />
                      </>
                    }
                  </>
                  : <div className="text-center text-barPrimary text-xl my-8">{t('NO_NUMERICAL_DATA')}</div>
              }
            </>
        }
      </VMCard>

      <VMCard
        className="my-4"
        titleClassName="m-4 mr-0"
        titleComponent={
          <div className="text-barPrimary text-lg font-bold uppercase">{t('STUDENT_PERFORMANCE')}: {filterTitle}</div>
        }
      >
        {
          teacherStore.loading || filterStore.loading || !sPerformanceData.boxData ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <>
              <div className='text-right'>
                {
                  teacherStore.teacherSubjectPerformList.map((tsp: any) => (
                    tsp.subjectPerformance.filter(s => s.isOwnClass == true)
                      .map((sp: any, index: number) => (
                        <Button
                          className={`rounded-lg text-white text-sm mr-4 ${sp.classCode === currentClassCode ? "bg-barPrimary" : "bg-gray-400"}`}
                          key={`tsp_button_${index}`}
                          component="span"
                          onClick={() => onChangeCurrentClassCode(sp.classCode, sortMode)}
                        >
                          {sp.classCode === currentClassCode ? t('CURRENT') + ": " + sp.classCode : sp.classCode}
                        </Button>
                      ))

                  ))
                }
              </div>
              <div className="flex justify-end mt-4">
                <div className="text-barPrimary pt-1 mr-4 font-semibold">{t('ORDER_BY')}:</div>
                <Button
                  className={`rounded-lg text-white text-sm mr-4 ${sortMode === SortMode.BY_NAME ? "bg-barPrimary" : "bg-gray-400"}`}
                  component="span"
                  onClick={() => onSortPerformanceList(SortMode.BY_NAME)}
                >
                  {t('BY_NAME')}
                </Button>
                <Button
                  className={`rounded-lg text-white text-sm mr-4 ${sortMode === SortMode.BY_SCORE ? "bg-barPrimary" : "bg-gray-400"}`}
                  component="span"
                  onClick={() => onSortPerformanceList(SortMode.BY_SCORE)}
                >
                  {t('BY_SCORE')}
                </Button>
              </div>
              {
                sPerformanceData.boxData.length > 0 ?
                  <IESBoxPlot
                    dataAndSetting={{
                      data: sPerformanceData,
                      height: '450px',
                      width: CHART_WIDTH,
                      xAxisLabelRotate: 45,
                      markComparisionData: sPerformanceData.markComparisionData,
                      min: minNum
                    }}
                    callback={onDirectStudentDetail}
                  />
                  : <div className="text-center text-barPrimary text-xl my-8">{t('NO_NUMERICAL_DATA')}</div>
              }
            </>
        }
      </VMCard>

    </>
  )
});

const MyStudentTab = observer(({ userInfo }) => {
  const { filterStore, studentStore } = useStores();
  const [studentList, setStudentList] = useState<any>();
  const [semester, setSemester] = useState<any>([0]);
  const [desc, setDesc] = useState<string>("");

  useEffect(() => {
    if (filterStore.academicYearList.length > 0) {
      if (filterStore.currentSemester == 0) filterStore.getCurrentSemester();
      let currentSemester = (typeof window !== "undefined" && localStorage.SD_TAB_HISTORY) ? JSON.parse(localStorage.SD_TAB_HISTORY).semester[0]
        : filterStore.currentSemester;
      let allHr = filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode);
      setSemester([currentSemester]);
      for (var y of filterStore.academicYearList) {
        if (y.academicSemesters.find(s => s.id == currentSemester)) {
          setDesc(y.yearDescription + " " + y.academicSemesters.find(s => s.id == currentSemester).semesterDescription);
          break;
        }
      }
      studentStore.getStudentList({
        semesterIdList: [currentSemester],
        // semesterIdList: [35],
        homeRoomCode: allHr,
        teacherUid: userInfo.uid
      }).then(response => {
        setStudentList(response);
        studentStore.getStudentOverallScoreAttendanceRate({
          // semesterIdList: [35],
          semesterIdList: [currentSemester],
          uidList: response.studentCards.map((data) => data.uid)
        })
      })
      delete localStorage.SD_TAB_HISTORY;
    }
  }, [filterStore.currentSemester]);

  const onApplyFilter = (dataInfo: any) => {
    console.log("GetTeacherSubjectPerformance", dataInfo);
    setSemester([dataInfo.semester]);
    let allHr = filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode);
    for (var y of filterStore.academicYearList) {
      if (y.academicSemesters.find(s => s.id == dataInfo.semester)) {
        setDesc(y.yearDescription + " " + y.academicSemesters.find(s => s.id == dataInfo.semester).semesterDescription);
        break;
      }
    }
    studentStore.getStudentList({
      semesterIdList: [dataInfo.semester],
      homeRoomCode: allHr,
      teacherUid: userInfo.uid
    }).then(response => {
      setStudentList(response);
      studentStore.getStudentOverallScoreAttendanceRate({
        semesterIdList: [dataInfo.semester],
        uidList: response.studentCards.map((data) => data.uid)
      })
    })
  }

  return (
    <>
      <StudentDetailFilter callback={onApplyFilter} />
      <div className='text-barPrimary ml-2 col-span-4 font-bold mt-4'>Student List: {desc}</div>
      <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4 mt-4">
        {
          studentStore.loadingOverview || !studentList ? <div className="w-full text-center col-span-4 p-20"><CircularProgress className="text-barPrimary" /></div>
            : !studentList || studentList.studentCards.length < 1 ? <div className='text-center text-2xl col-span-4'>{t('NO_STUDENTS_FOUND')}</div>
              : studentList.studentCards.map((std, index) => (
                <button
                  key={`student_card_${index}`}
                  onClick={() => {
                    localStorage.SD_TAB_HISTORY = JSON.stringify({ tab: 2, semester })
                    navigate(`/student-detail/`, { state: { student: std.uid, semester: semester, studentList: studentList.studentCards } });
                  }}
                >
                  <StudentCard {...std} />
                </button>
              ))}
      </div>
    </>

  )
})

export default Dashboard;

