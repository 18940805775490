import React from "react";
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react";
import useStores from "../hooks/use-stores";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SchoolIcon from '@material-ui/icons/School';
const StudentCard = observer(({
    img,
    uid,
    firstName,
    surname,
    classCode,
    setStudentId,
    image,
    mentorName,
    highlightClassCode = "",
}) => {
    const { studentStore } = useStores();
    const { t } = useTranslation();
    const handleRoute = () => {
        if (setStudentId) setStudentId(uid);
    }

    return (
        <div className="rounded-xl border shadow-lg bg-white flex flex-col pl-4 p-6 cursor-pointer hover:shadow-sm" onClick={handleRoute}>
            <div className="flex flex-row">
                <div className="w-20 h-20">
                    <img
                        src={image}
                        alt="img"
                        className="w-full h-full object-cover"
                    />
                </div>

                <div className="flex flex-col ml-2 text-xs">
                    <div className="mb-2">
                        {t('STUDENT')}ID: {uid}
                    </div>
                    <div className="mb-2">
                        {t('FIRST_NAME')}: {firstName}
                    </div>
                    <div>
                        {t('SURNAME')}: {surname}
                    </div>
                </div>
            </div>
            {
                studentStore.sScoreAndAttendanceData.length > 0 &&
                studentStore.sScoreAndAttendanceData.filter((data) => data.uid == uid)
                    .map((sSA) => (
                        <>
                            <div className="grid grid-cols-2 border-b border-t border-gray-200 mt-2 py-1">
                                <div className={`${+sSA.score >= 80 ? 'text-green-500' : +sSA.score >= 60 ? 'text-yellow-400' : 'text-red-500'}`}>
                                    <SchoolIcon color="inherit" />
                                    <span className="ml-1 text-black text-sm">
                                        {sSA.score}%
                                    </span>
                                </div>
                                <div className={`${+sSA.attendanceRate >= 80 ? 'text-green-500' : +sSA.attendanceRate >= 60 ? 'text-yellow-400' : 'text-red-500'}`}>
                                    <QueryBuilderIcon color="inherit" />
                                    <span className="ml-1 text-black text-sm">
                                        {sSA.attendanceRate}%
                                    </span>
                                </div>
                            </div>
                        </>
                    ))
            }
            <div className="mt-2 text-xs text-left pr-8 text-red-500">
                <p>{t('SUBJECTS')}:</p>
            </div>
            <div className="mt-2 text-xs text-left pr-8">
                {classCode.map((item, index) => <span key={`demo_snap_${index}`} className={`${highlightClassCode === item && 'text-red-500'}`}>{(index ? ', ' : '') + item}</span>)}
            </div>

            <div className="mt-2 text-xs text-left pr-8 text-red-500">
                <p>{t('MENTOR')}:</p>
            </div>
            <div className="mt-2 text-xs text-left pr-8">
                {mentorName == "" ? "No Result" : mentorName.map((item, index) => <span key={`mentor_${index}`}>{(index ? ', ' : '') + item}</span>)}
            </div>
        </div>
    )
})

export default StudentCard;